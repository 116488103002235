.question1Root {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.question1Content {
  width: 70.4%;
  margin-right: 11.1%;
  font-size: 1.375rem;
}

.questionItem:not(:last-child) {
  margin-bottom: 1.06rem;
}

.questionTitle {
  margin-bottom: 0.75rem;
  width: calc(100% - 5.81rem);
}

.questionInfo {
  display: flex;
  align-items: center;

  & > div:first-child {
    flex-grow: 1;
    margin-right: 2.31rem;
    height: 2.5rem;
  }

  & > div:last-child {
    width: 3.5rem;
    font-weight: 700;
  }
}

.commentsBoard {
  margin-top: 5rem;
}

.commentHeader {
  font-weight: bold;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
}

.commentItem {
  margin-top: 0.8rem;
  word-break: break-all;
}