.wrapperOuter {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background: url('../../assets/nImages/background_cover.png');
  padding: 1rem 3rem 3rem 3rem;
  background-size: cover;
  background-repeat: no-repeat;
}

.wrapperHeader {
  // height: 70px;
  margin-bottom: 10px;
  display: flex;
}

.wrapperInner {
  display: flex;
  width: 100%;
  height: calc(100% - 10rem);
  flex-grow: 1;
}

.wrapperMenu {
  width: 22rem;
  display: flex;
  flex-flow: column;
}

.wrapper {
  width: calc(100% - 22rem);
  display: flex;
  flex-direction: column;
  height: 100%;
  &.loginPage{
     width: 100%;
  }
}

.eventBackgroundContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  pointer-events: none;
  z-index: 1;
  .event_background {
    max-width: 100%;
    max-height: 100%;
    display: block;
  }
}
