// @import "src/assets/styles/media";

.videoPopup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 31;
  }
  
  .videoPopup__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    &.smallVideo {
      max-width: 33.06rem;
      max-height: 35.31rem;
    }
  }
  
  .videoPopup__pic {
    max-width: 700px;
    padding: 0.81rem;
    background-color: var(--color-white);
    border: 1px solid var(--color-lightgreen3);
    border-radius: 0.75rem;
    position: relative;
    // @include desktop-l {
    //   margin: 1.875rem;
    // }
  }
  
  .videoPopup__img {
    width: 100%;
    height: 100%;
    max-width: 80vw;
    max-height: 80vh;
    object-position: center;
    object-fit: cover;
  }
  
  .videoPopupClose {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 4;
    background-color: rgba(128, 128, 128, 0.288);
  }
  
  .videoPopup__close {
    position: absolute;
    top: -1.845rem;
    right: -1.845rem;
    padding: 0.81rem;
    width: 3.69rem;
    height: 3.69rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--color-lightgreen3);
    cursor: pointer;
    z-index: 15;
  }
  
  .videoPopup__closeSvg {
    fill: var(--color-white);
    width: 100%;
    height: 100%;
    object-position: center;
  }
  