.header {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.headerStart {
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerInfo {
  display: flex;
  align-items: center;
  height: 100%;
}

.headerText {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.headerText__inner {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
  color: var(--color-gray1);
}

.headerPharmaceutical {
  margin-left: 2.31rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  img {
    width: 100%;
    height: auto;
    padding-right: 0.5rem;
  }
}

.userRole {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  color: var(--color-darkblue);
  padding-right: 0.75rem;
}

.superUser {
  color: var(--color-lightblue);
}

.headerPharmaceutical__icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerPharmaceutical__svg {
  width: 120px;
  height: 65px;
  object-position: center;
}
