.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.contentBoard {
  flex-grow: 1;
  margin-top: 1.31rem;
  display: flex;
  overflow: hidden;
}

.reportBody{
  width: 100%;
  .currentQuestionHeader{
    width: 100%;
    padding: 1.5rem;
    margin: 1rem;
    margin-top: 0;
    background-color: grey;
    color: white;
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

.currentPage {
  flex-grow: 1;
  padding: 0.875rem 1.43rem 0.875rem 4.125rem;
  // overflow-y: auto;
  height: 100%;
}

.reportPage{
  // overflow-y: auto;
}

.noEvent {
  margin-top: 2rem;
  width: 100%;
}

.headingCheckboxRoot {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  font-size: 1.125rem;
  cursor: pointer;
}

.headingCheckbox {
  margin-right: 1rem;

  & > div {
    background-color: white;
  }
}

.headingCheckboxTitle > div:first-child {
  margin-bottom: 0.3rem;
}