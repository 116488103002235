.heading {
  width: 100%;
  background: linear-gradient(180deg, #C2DEC4 0%, #A3D7E9 100%);
  min-height: 10rem;
  border-radius: 1rem 1rem 0 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 2.19rem;
  padding-right: 1.69rem;
  // justify-content: center;
  position: relative;
}

.headingTitle {
  font-size: 3.25rem;
  line-height: 4rem;
  font-weight: 700;
  min-height: 6rem;
  display: flex;
  align-items: flex-end;
}

.actions {
  display: flex;
  // align-items: center;
  width: 100%;
  height: 100%;
}

.moderatorReset {
  margin-right: 1rem;
  border-radius: 0.375rem;
  font-size: 1rem;
  height: 3rem;
}