.root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.statisticsWrapper {
  width: 100%;
  height: 85%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  flex: 1;
}

.statistics {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.statisticRow {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;
  // & :first-child{
  //   width: 70%;
  // }
  // &:not(:first-child){
  //   width: 30%;
  // }
}

.statisticTitle {
  font-size: 1.9rem;
  line-height: 2.5rem;
  font-weight: bold;
  color: var(--color-menuGreen);
  text-align: center;
  line-height: 1.3;
  margin-bottom: 5rem;
}

.statisticItems {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.description {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.125rem;
  margin-top: 1rem;
  text-align: center;

  & > span:last-child {
    font-weight: bold;
    margin-top: 0.3rem;
  }
}
