.commentsBoard {
  margin-top: 3rem;
}

.commentHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.imageView {
  cursor: pointer;
}