.customerSatisfaction {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.satisfactionBoard {
  flex-grow: 1;
  width: 100%;
  display: flex;
  overflow-y: auto;
  margin-top: 1.94rem;
  margin-bottom: 1rem;
  justify-content: space-between;
}

.satisfactionItem {
  width: 45%;
}

.satisfactionItem:not(:first-child) {
  margin-left: 6.18rem;
}

.noEvent {
  margin-top: 2rem;
  width: 100%;
}

.commentsBoard{
  width: 100%;
  justify-content: center;
  background-color: #d9d9d9;
  padding: 1.5rem;
  border-radius: 10px;
  margin-top: 1.5rem;

  .comment{
    font-size: 1.25rem;
    line-height: 2rem;
  }
}
