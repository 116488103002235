.pointer {
  cursor: pointer;
}

.headerCell {
  display: flex;
  align-items: center;
}

.dropdownIconWrapper {
  width: 28px;
  height: 28px;
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid var(--color-gray2);
  cursor: pointer;
}

.dropdownIcon {
  width: 14px;
  height: 8px;
  background-image: url('../../../assets/images/arrowDown.svg');
  background-size: contain;
  background-repeat: no-repeat;
}

.dropdownIconActive {
  transform: rotate(180deg);
}

.dropdown {
  position: absolute;
  top: unset;
  bottom: 0;
  left: 0;
}

.dropdownList {
  font-size: 1.125rem;
  cursor: default;
  max-height: 300px;
  overflow-y: auto;
  padding: 1rem;
}

.dropSelectAll {
  border-bottom: 1px solid var(--color-gray2);
}

.dropdownItem {
  padding: 1rem 0;
  cursor: pointer;
  display: flex;
  align-items: center;

  & > div:first-child {
    flex-grow: 1;
  }

  & > div:last-child {
    margin: 0 1.5rem;
  }
}