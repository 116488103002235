.table {

}

.table__header {
  margin-bottom: 0.5rem;
  width: 100%;
  // height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-gray6);
  border-radius: 0.375rem;
  position: relative;
  padding: 1rem 1rem;
}

.table__headerCell {
  padding-left: 1.25rem;
  display: flex;
  align-items: center;
  position: relative;

  font-size: 1.37rem;
  font-weight: 700;
  color: black;

  & *:first-letter {
    text-transform: uppercase;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 2.125rem;
    border-radius: 1px;
    background-color: var(--color-white);
  }

  &:first-child:before {
    display: none;
  }
}

.table__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.table__row {
  margin-bottom: 0.5rem;
  padding: 0.25rem 1rem;
  width: 100%;
  min-height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-whitegray);
  border-radius: 0.375rem;
  font-size: 1.125rem;

  &:nth-child(2n + 2) {
    background-color: var(--color-white2);
  }
}

.table__cell {
  padding-left: 1.25rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.69rem;
  color: var(--color-gray2);
}
